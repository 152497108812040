import { locale as moment_locale, updateLocale as moment_updateLocale } from 'moment';

export function configureMoment(userLocale: string): void {
    moment_updateLocale(userLocale, {
        calendar: {
            sameElse: 'L LT', // Make calendar dates older than a week also show the time.
        },
    });

    // Set the locale moment uses.
    moment_locale(userLocale);
}

export function nthWeekdayOfMonth(year: number, month: number, nth: number, dow: number): Date {
    const d = new Date(year, month - 1, 7 * (nth - 1) + 1);
    const w = d.getDay();
    d.setDate(d.getDate() + ((7 + dow - w) % 7));
    if (d.getMonth() + 1 > month || d.getFullYear() > year) d.setDate(d.getDate() - 7);
    return d;
}
